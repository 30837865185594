import React, { useContext } from "react"
import Layout from "../../components/layout"
import NullIf from "../../services/NullIf"

import r from "../../services/Router"
import { graphql, Link } from "gatsby"
import ThemeContext from "../../context/ThemeContext"
import SEO from "../../components/seo"
import GenericLandingContentWrapper from "../../components/text-helpers/generic-landing-content-wrapper"
import MainSearch from "../../components/main-search/main-search"
import h from "../../services/GenericHelpers"
import localize from "../../components/localize"
import { localizeItem } from "../../graphql-assistant/createLocaleTextGetter"

const Content = ({ bonuses, locale }) => {
  return (
    <div className="cards-injected">
      <MainSearch locale={locale} bonuses={bonuses} promotionalText={x => {
        if (x.extendedData) {
          const data = localizeItem(x.extendedData, [locale])
          if (data.raffleText)
            return localizeItem(data.raffleText, [locale]);
        }

        return false;
      }} />
    </div>
  )
}

const Raffle = ({ location, data, pageContext }) => {
  let { locale, alternateLocaleSlugs } = pageContext

  let site = useContext(ThemeContext)
  let bonuses = localizeItem(site.bonuses, [locale]);
  bonuses = bonuses.slice().filter(x => x.gameProviders.some(y => y.name === 'Relax Gaming'));

  let pageData = data.sanityGenericLandingPage

  if (!pageData) pageData = {}

  return (
    <Layout breadcrumbNameOverride={pageData.breadcrumbTrail} pageContext={pageContext} location={location}>
      <SEO location={location} bonuses={bonuses}
           title={pageData && pageData.seoTitle}  alternateSlug={alternateLocaleSlugs}
           description={pageData && pageData.seoMeta}
      />
      <GenericLandingContentWrapper
        pageData={pageData}
        bodyClassName={`gambleGeneralWrapper`}
        headerClassName={`gambleGeneralWrapper`}
        useFullBody={true}
      >
        <Content bonuses={bonuses} locale={locale} />
      </GenericLandingContentWrapper>
    </Layout>
  )
}

export default localize(Raffle)
export const pageQuery = graphql`
  query RaffleLanding {
    sanityGenericLandingPage(
      name: { eq: "Raffles" }
    ) {
      ...GenericLanding
    }
  }
`
